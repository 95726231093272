
<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-1"
      collapse-action
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/5 sm:w-1/2  w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="search.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="search.DateTo"
            class=" w-full"
            name="to"
          ></datepicker>
        </div>


        <div
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("doctors") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.DoctorID"
            :options="doctors"
            :reduce="ID => ID.ID"
          />
        </div>

     
    

        <div class="vx-col  w-full">
          <div class="vx-row">
            <vs-button
              style="margin:10px"
              class="my-3"
              @click="doctorSessionSearch"
              v-scroll-to="'#Scroll'"
              >{{ $t("Search") }}</vs-button
            >
            <vs-button
              style="margin-left:10px"
              class="my-3"
              @click="resetData"
              >{{ $t("Reset") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Reservations"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                Reservations.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : Reservations.length
              }}
              of {{ Reservations.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="ReferenceNumber">{{ $t("Reference Number") }}</vs-th>
        <vs-th sort-key="Doctor">{{ $t("doctor") }}</vs-th>
        <vs-th sort-key="Date">{{ $t("Date") }}</vs-th>
        <vs-th sort-key="DoctorPrice">{{ $t("Doctor Price") }}</vs-th>
        <vs-th sort-key="MarkupFees">{{ $t("Markup Fees") }}</vs-th>

        <vs-th>{{ $t("Settlement") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
             <vs-td>
              <p              
                class="product-name font-medium truncate"
              >
                {{ tr.ReferenceNumber }}
              </p>
            </vs-td>
            <vs-td>
              <p
                v-if="tr.Doctor.Name"
                class="product-name font-medium truncate"
              >
                {{ tr.Doctor.Name }}
              </p>
            </vs-td>

            <vs-td>
              <p
                v-if="tr.DoctorSession.DayUTC"
                class="product-name font-medium truncate"
              >
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.DoctorSession.DayUTC | formatDate() }}
              </p>
            </vs-td>
              <vs-td>
              <p
                v-if="tr.DoctorSession.Price"
                class="product-name font-medium truncate"
              >
                {{ tr.DoctorSession.Price }}
              </p>
            </vs-td>
                 <vs-td>
              <p
                v-if="tr.MarkupFees"
                class="product-name font-medium truncate"
              >
                {{ tr.MarkupFees }}
              </p>
            </vs-td>

            <vs-td class="whitespace-no-wrap"  v-if="!tr.IsEquation">
          <vx-tooltip :text="$t('patient Reservartion Settlement')" position="top" style="display:inline">
               
                <feather-icon
                  
                  icon="CheckSquareIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                  @click.stop="setEquation(tr.ID)"
                />
          </vx-tooltip>
              
            </vs-td>
          <vs-td v-else>
              <p
                
                class="product-name font-medium truncate"
              >
                Done
              </p>
            </vs-td>


          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vx-card
      v-bind:title="$t('Results')"
      style="height:9rem"
    >
      <div class="vx-row " style="margin-top:-1rem !important" >
        <div class="vx-col md:w-1/5 sm:w-1/2  text-center w-full ">
          <h6 >{{ $t("Total Doctor Prices") }}</h6>
            <p style="Color:red !important;margin-top:1rem;">{{totalDoctorPrices}} $</p>
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full  text-center">
           <h6 >{{ $t("Total Markup Fees") }}</h6>
          <p style="Color:red !important;margin-top:1rem;">{{totalMarkupFees}} $</p>

        </div>

   <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2 text-center">
                <h6 >{{ $t("Total Net Value") }}</h6>
          <p style="Color:red !important;margin-top:1rem;">{{totalNet}} $</p>

        </div>

      </div>
    </vx-card>

  </div>
</template>

<script>
import moduleReservation from "@/store/reservation/ReservationSession/moduleReservation.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      selected: [],
      search: {},
      totalDoctorPrices:0,
      totalMarkupFees:0,
      totalNet:0,
      // Patients: [],
      itemsPerPage: 10,
      isMounted: false,
      rowDataForDelete: {},
      activeConfirm: false
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Reservations() {
      return this.$store.state.ReservationList.Reservations;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },

  },

  methods: {
    CalculateResults(){
      debugger;
      var Reservation=this.Reservations;
      if(Reservation!=null && Reservation.length>0)
      {
      for(let j = 0; j < Reservation.length; j++){ 
          this.totalDoctorPrices=this.totalDoctorPrices+ Reservation[j].DoctorSession.Price;
          this.totalMarkupFees=this.totalMarkupFees+ Reservation[j].MarkupFees;

       }
        this.totalNet=this.totalDoctorPrices+this.totalMarkupFees;

       
       }

    },

    resetData() {
  
      this.search = {};
      this.search.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.search.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
    this.search.PaymentStatusID = 2 ; //
   
       
    
    },
    doctorSessionSearch() {

      debugger
      this.$store.dispatch(
        "ReservationList/SearchPatientReservationSessions",
        this.search
      ).then((res)=>{
        this.CalculateResults();
      });
     
    },
    setEquation(ID){
        debugger;
      this.$store.dispatch("ReservationList/EquationReservedSession",ID).then((res)=>{
                    this.$vs.notify({
                      title: this.$t("success"),
                      text: res.data.Message,
                      iconPack: "feather",
                      icon: "icon-alert-circle",
                      color: "success",
                    });
            this.doctorSessionSearch();

             }).catch((err)=>{
               debugger
                this.$vs.notify({
                  title: this.$t("Error"),
                  text: err.data.Message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
                
             });
   
    }

  },

  created() {
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    this.$store.dispatch("DoctorList/GetAllDoctors", {});
    

    this.resetData();
    this.doctorSessionSearch();
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }


      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
